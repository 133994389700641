import { memo, useCallback } from 'react'
import { Currency, Pair, Token, CurrencyAmount } from '@pancakeswap/sdk'
import {
  Button,
  Text,
  useModal,
  Flex,
  Box,
  CopyButton,
  Loading,
  Skeleton,
  Swap as SwapUI,
  ArrowDropDownIcon,
} from '@pancakeswap/uikit'
import styled, { css } from 'styled-components'
import { isAddress } from 'utils'
import { useTranslation } from '@pancakeswap/localization'
import { WrappedTokenInfo } from '@pancakeswap/token-lists'
import { formatAmount } from '@pancakeswap/utils/formatFractions'

import { useStablecoinPriceAmount } from 'hooks/useBUSDPrice'
import { formatNumber } from '@pancakeswap/utils/formatBalance'

import { useAccount } from 'wagmi'
import { useCurrencyBalance } from '../../state/wallet/hooks'
import CurrencySearchModal from '../SearchModal/CurrencySearchModal'
import { CurrencyLogo, DoubleCurrencyLogo } from '../Logo'

import AddToWalletButton from '../AddToWallet/AddToWalletButton'

const InputRow = styled.div<{ selected: boolean }>`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-end;
`

const CurrencySelectButton = styled(Button).attrs({ variant: 'text', scale: 'sm' })<{ zapStyle?: ZapStyle }>`
  padding: 0px;

  ${({ zapStyle, theme }) =>
    zapStyle &&
    css`
      padding: 8px;
      background: ${theme.colors.background};
      border: 1px solid ${theme.colors.cardBorder};
      border-radius: ${zapStyle === 'zap' ? '0px' : '8px'} 8px 0px 0px;
      height: auto;
    `};
`

const USDPriceWrap = styled(Flex)`
  position: relative;
  top: 2px;
`

const SelectCurrencyLabel = styled.span`
  display: inline-block;
  padding-left: 12px;
`

type ZapStyle = 'noZap' | 'zap'

interface CurrencyInputPanelProps {
  value: string
  onUserInput: (value: string) => void
  onInputBlur?: () => void
  onPercentInput?: (percent: number) => void
  onMax?: () => void
  showQuickInputButton?: boolean
  showMaxButton?: boolean
  maxAmount?: CurrencyAmount<Currency>
  lpPercent?: string
  label?: string
  onCurrencySelect?: (currency: Currency) => void
  currency?: Currency | null
  disableCurrencySelect?: boolean
  hideBalance?: boolean
  pair?: Pair | null
  otherCurrency?: Currency | null
  id: string
  showCommonBases?: boolean
  commonBasesType?: string
  showSearchInput?: boolean
  zapStyle?: ZapStyle
  beforeButton?: React.ReactNode
  disabled?: boolean
  error?: boolean | string
  showUSDPrice?: boolean
  tokensToShow?: Token[]
  currencyLoading?: boolean
  inputLoading?: boolean
  title?: React.ReactNode
  hideBalanceComp?: boolean
  isModal?: boolean
}
const CurrencyInputPanel = memo(function CurrencyInputPanel({
  value,
  onUserInput,
  onInputBlur,
  onMax,
  showMaxButton = false,
  maxAmount,
  label,
  onCurrencySelect,
  currency,
  disableCurrencySelect = false,
  hideBalance = false,
  zapStyle,
  pair = null, // used for double token logo
  otherCurrency,
  id,
  showCommonBases,
  commonBasesType,
  showSearchInput,
  disabled,
  error,
  showUSDPrice,
  tokensToShow,
  currencyLoading,
  inputLoading,
  title,
  hideBalanceComp,
  isModal,
  // temporary disable for Liquidity
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onPercentInput,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  showQuickInputButton = false,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  lpPercent,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  beforeButton,
}: CurrencyInputPanelProps) {
  const { address: account } = useAccount()

  const selectedCurrencyBalance = useCurrencyBalance(account ?? undefined, currency ?? undefined)
  const { t } = useTranslation()

  const mode = id
  const token = pair ? pair.liquidityToken : currency?.isToken ? currency : null
  const tokenAddress = token ? isAddress(token.address) : null

  const amountInDollar = useStablecoinPriceAmount(
    showUSDPrice ? currency : undefined,
    Number.isFinite(+value) ? +value : undefined,
    {
      hideIfPriceImpactTooHigh: true,
      enabled: Number.isFinite(+value),
    },
  )

  const [onPresentCurrencyModal] = useModal(
    <CurrencySearchModal
      onCurrencySelect={onCurrencySelect}
      selectedCurrency={currency}
      otherSelectedCurrency={otherCurrency}
      showCommonBases={showCommonBases}
      commonBasesType={commonBasesType}
      showSearchInput={showSearchInput}
      tokensToShow={tokensToShow}
      mode={mode}
    />,
  )

  const handleUserInput = useCallback(
    (val: string) => {
      onUserInput(val)
    },
    [onUserInput],
  )

  const onCurrencySelectClick = useCallback(() => {
    if (!disableCurrencySelect) {
      onPresentCurrencyModal()
    }
  }, [onPresentCurrencyModal, disableCurrencySelect])

  const balance = !hideBalance && !!currency && formatAmount(selectedCurrencyBalance, 6)
  return (
    <SwapUI.CurrencyInputPanel
      id={id}
      disabled={disabled}
      error={error as boolean}
      zapStyle={zapStyle}
      value={value}
      onInputBlur={onInputBlur}
      onUserInput={handleUserInput}
      loading={inputLoading}
      isModal={isModal}
      top={
        <>
          {title}
          <Flex alignItems="center">
            <CurrencySelectButton
              zapStyle={zapStyle}
              className="open-currency-select-button"
              selected={!!currency}
              onClick={onCurrencySelectClick}
            >
              <Flex alignItems="center" justifyContent="space-between">
                {pair ? (
                  <DoubleCurrencyLogo currency0={pair.token0} currency1={pair.token1} size={16} margin />
                ) : currency ? (
                  <CurrencyLogo currency={currency} size="24px" style={{ marginRight: '4px' }} />
                ) : currencyLoading ? (
                  <Skeleton width="24px" height="24px" variant="circle" />
                ) : null}
                {currencyLoading ? null : pair ? (
                  <Text id="pair" medium color="gray100">
                    {pair?.token0.symbol}:{pair?.token1.symbol}
                  </Text>
                ) : (
                  <Text id="pair" medium color="gray100">
                    {(currency && currency.symbol && currency.symbol.length > 10
                      ? `${currency.symbol.slice(0, 4)}...${currency.symbol.slice(
                          currency.symbol.length - 5,
                          currency.symbol.length,
                        )}`
                      : currency?.symbol) || <SelectCurrencyLabel>{t('Select a currency')}</SelectCurrencyLabel>}
                  </Text>
                )}
                {!currencyLoading && !disableCurrencySelect && (
                  <ArrowDropDownIcon color="gray100" width={24} height={24} />
                )}
              </Flex>
            </CurrencySelectButton>
            {token && tokenAddress ? (
              <Flex style={{ gap: '4px' }} ml="4px" alignItems="center">
                <CopyButton
                  width="20px"
                  buttonColor="gray200"
                  text={tokenAddress}
                  tooltipMessage={t('Token address copied')}
                />
                <AddToWalletButton
                  variant="text"
                  p="0"
                  height="auto"
                  width="fit-content"
                  tokenAddress={tokenAddress}
                  tokenSymbol={token.symbol}
                  tokenDecimals={token.decimals}
                  tokenLogo={token instanceof WrappedTokenInfo ? token.logoURI : undefined}
                />
              </Flex>
            ) : null}
          </Flex>
          {account && !hideBalanceComp && (
            <Text
              onClick={!disabled && onMax}
              color="gray100"
              letterSpacing="0.6px"
              lineHeight={1.75}
              ellipsis
              title={!hideBalance && !!currency ? t('Balance: %balance%', { balance: balance ?? t('Loading') }) : ' -'}
              style={{ display: 'inline', opacity: 0.5 }}
            >
              {!hideBalance && !!currency
                ? balance?.replace('.', '')?.length > 12
                  ? balance
                  : t('Balance: %balance%', { balance: balance ?? t('Loading') })
                : ' -'}
            </Text>
          )}
        </>
      }
      right={
        <InputRow selected={disableCurrencySelect}>
          {account && currency && selectedCurrencyBalance?.greaterThan(0) && !disabled && label !== 'To' && (
            <Flex alignItems="right" justifyContent="right">
              {maxAmount?.greaterThan(0) && showMaxButton && (
                <Button
                  py="4px"
                  px="8px"
                  onClick={(e) => {
                    e.stopPropagation()
                    e.preventDefault()
                    onMax?.()
                  }}
                  variant="primary-text"
                  style={{ textTransform: 'uppercase', fontSize: '16px', fontWeight: 500 }}
                >
                  {t('Max')}
                </Button>
              )}
            </Flex>
          )}
        </InputRow>
      }
      bottom={
        <>
          {!!showUSDPrice && (
            <USDPriceWrap maxWidth="200px">
              {inputLoading ? (
                <Loading width="16px" height="16px" />
              ) : showUSDPrice && Number.isFinite(amountInDollar) ? (
                <Text fontSize="12px" lineHeight={1.333} color="white300" medium ellipsis>
                  {`~${formatNumber(amountInDollar)} USD`}
                </Text>
              ) : (
                <Box height="16px" />
              )}
            </USDPriceWrap>
          )}
        </>
      }
    />
  )
})

export default CurrencyInputPanel
